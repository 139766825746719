import axios, { AxiosResponse } from "axios";
import { AppDispatch, RootState } from "../store/store";
import { authActions } from "./slice";
import { Action, ThunkAction } from "@reduxjs/toolkit";
import { generateRandomState } from "../utils/generator";
import { UserProfile } from "./type";
import { API_URL } from "../utils/apiUrls";
import { router } from "../navigation";

export const client = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  withCredentials: true,
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    console.log("err:-", err);
    console.log(err?.response?.status);
    if (err?.response?.status === 401 || err?.code === "ERR_NETWORK") {
      // login();
      router.navigate("/login");
    }
    console.log(err);
  }
);

export const login = () => {
  const url = new URL(
    `${process.env.REACT_APP_AUTH_URL}${API_URL.AUTHORIZATION}`
  );
  url.searchParams.append("scope", process.env.REACT_APP_AUTH_SCOPE);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("client_id", process.env.REACT_APP_AUTH_CLIENT_ID);
  url.searchParams.append(
    "redirect_uri",
    `${window.location.origin}${process.env.REACT_APP_REDIRECT_URI}`
  );
  url.searchParams.append("state", generateRandomState());

  window.location.href = url.href;
};

export const logout = () => {
  const request = client.get(API_URL.LOGOUT);
  return request;
};

export const getAccounts = () => {
  const request = client.get(API_URL.GET_USER_ACCOUNTS);
  return request;
};

export const fetchContentRepoToken = (userName: string) => {
  const request = client.get(`${API_URL.GET_ACCESS_TOKEN}`, {
    params: {
      username: userName,
    },
  });

  return request;
};

export const getUserProfile = () => {
  const request = client.get(API_URL.GET_USER_PROFILE);
  return request;
};

export const fetchContentRepoInformation = (
  navigate: any
): ThunkAction<void, RootState, any, Action> => {
  return (dispatch: AppDispatch, getState) => {
    getUserProfile().then((response: AxiosResponse<UserProfile>) => {
      if (response?.data) {
        dispatch(
          authActions.setContentRepoToken({ contentRepoAccessToken: "" })
        );
        dispatch(authActions.setIsLoggedIn({ isLoggedIn: true }));
        dispatch(authActions.setUserProfile({ user: response?.data }));

        fetchContentRepoToken(response.data.email).then((response) => {
          dispatch(
            authActions.setContentRepoToken({
              contentRepoAccessToken: response.data,
            })
          );
        });
        const accounts = response.data?.accounts?.filter((account) => {
          return account.has_audit;
        });
        const selectedAccountId = getState().authReducer.selectedAccount?.id;
        if (accounts.length === 0) {
        } else if (accounts.length === 1) {
          dispatch(authActions.setSelectedAccount({ account: accounts[0] }));
          const initialUrl = localStorage.getItem("initialUrl");
          localStorage.removeItem("initialUrl");
          navigate('/contents/')
          navigate(initialUrl ?? "/contents/", {
            relative: "path",
          });
        } else {
          if (selectedAccountId) {
            const selectedAccountIndex = accounts.findIndex(
              (accounts) =>
                selectedAccountId && selectedAccountId === accounts.id
            );
            if (selectedAccountIndex !== -1) {
              const initialUrl = localStorage.getItem("initialUrl");
              localStorage.removeItem("initialUrl");
              navigate('/contents/')
              navigate(initialUrl ?? "/contents/", {
                relative: "path",
              });
            } else {
              navigate("/accounts/", {
                replace: true,
                state: { isMenu: false },
                relative: "path",
              });
            }
          } else {
            navigate("/accounts/", { replace: true, state: { isMenu: false } });
          }
        }
      }
    });
  };
};
