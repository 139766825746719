import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { authReducer } from "../auth/slice"
import persistReducer from "redux-persist/es/persistReducer"
import { persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

const authPersistConfig = {
  key: "authReducer",
  storage,
  blacklist: ["isLoggedIn", "initalRoute", "token"], // Blacklist isLoggedIn in the authReducer
}

// Persist the authReducer with its specific configuration
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer)

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["authReducer"],
}

const reducers = combineReducers({
  authReducer: persistedAuthReducer,
})
const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)

export default store
