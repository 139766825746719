import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useNavigation } from 'react-router-dom';

const NavigationHistoryContext = createContext({});

export const useNavigationHistory = () => useContext(NavigationHistoryContext);

const NavigationHistoryProvider = ({ children }: PropsWithChildren<any>) => {


  
  const [navigationHistory, setNavigationHistory] = useState([]);

  useEffect(() => {
    // const unlisten = history.listen((location) => {
    //   setNavigationHistory((prevHistory) => [
    //     ...prevHistory,
    //     { pathname: location.pathname, search: location.search },
    //   ]);
    // });
    // window.addEventListener('pushstate', (event) => {
    //     console.log('pushstate', event)
    // })
    // window.addEventListener('popstate', (event) => {
    //     console.log('popstate', event)
    // })

    return () => {
    //   unlisten();
    };
  }, [window.history]);

  return (
    <NavigationHistoryContext.Provider value={navigationHistory}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export default NavigationHistoryProvider;
