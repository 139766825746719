import { useEffect } from "react";
import { fetchContentRepoInformation } from "../auth/service";
import SplashScreen from "../components/splashScreen/splashScreen";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import DefaultLoading from "../components/loadingScreens/DefaultLoading";
import { authActions } from "../auth/slice";

const AppContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get('accountId');

  const isLoggedIn = useAppSelector((state) => state.authReducer.isLoggedIn);
  const account = useAppSelector((state) =>
    accountId
      ? state.authReducer.userProfile?.accounts.find(
          (account) => account.id === parseInt(accountId)
        )
      : null
  );
  useEffect(() => {
    console.log("---------------------------------")
    console.log(window.location);
    if (window.location.pathname?.startsWith("/content")) {
      localStorage.setItem("initialUrl", window.location.pathname);
    }
    dispatch(fetchContentRepoInformation(navigate));
  }, []);

  useEffect(() => {

    if (account) {
      dispatch(authActions.setSelectedAccount({ account: account }));
    }
  }, [account]);

  if (isLoggedIn) {
    return <Outlet />;
  }

  if (location.state?.isRedirect) {
    return <DefaultLoading />;
  }

  return <SplashScreen />;
};

export default AppContainer;
