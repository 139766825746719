import splashLogo from './splashLogo.svg'
const SplashScreen = () => {
  return (
    <div
      style={{
        // backgroundColor: "red",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: "flex",
        zIndex: 100,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <img src={splashLogo} alt='splash logo'></img>
      </div>
    </div>
  );
};

export default SplashScreen;
