import NavBar from "../components/appBar/NavBar"
import { useAppSelector } from "../store/hooks"
import { FileRepository } from "@meazureupgithub/contenthubcore"
import DefaultLoading from "../components/loadingScreens/DefaultLoading"

const ContentRepoContainer = () => {
  const token = useAppSelector(
    (state) => state.authReducer.contentRepoAccessToken
  )
  const accountId = useAppSelector(
    (state) => state.authReducer.selectedAccount?.id
  )
  const userId = useAppSelector((state) => state.authReducer.userProfile?.id)

  if (!accountId || !token || !userId) {
    return <DefaultLoading />
  }

  return (
    <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}>
      <NavBar />
      <div style={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
        <FileRepository
          token={token}
          accountId={accountId}
          userId={userId}
          baseUrl={process.env.REACT_APP_CONTENT_REPO_BASE_URL}
        />
      </div>
    </div>
  )
}

export default ContentRepoContainer
