import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import DefaultLoading from "../components/loadingScreens/DefaultLoading"

const AuthCallbackContainer = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/")
  }, [navigate])
  return <DefaultLoading />
}

export default AuthCallbackContainer
