import { useEffect } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import AuthCallbackContainer from "../container/AuthCallbackContainer"
import LandingContainer from "../container/LandingContainer"
import AccountsContainer from "../container/AccountsContainer"
import LoginContainer from "../container/LoginContainer"
import ContentRepoContainer from "../container/ContentRepoContainer"
import { useAppDispatch } from "../store/hooks"
import { authActions } from "../auth/slice"


export const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginContainer />,
  },
  {
    path: "/oauth/content-repository/callback/",
    element: <AuthCallbackContainer />,
  },
  {
    path: "/accounts/",
    element: <AccountsContainer />,
  },
  {
    path: "/",
    element: <LandingContainer />,
    children: [
      {
        path: "/contents/",
        element: <ContentRepoContainer />,
        index: true,
      },
      {
        path: "/contents/*",
        element: <ContentRepoContainer />,
        // index:true,
      },
    ],
  },
])


const AppRouter = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const initalPath = window.location.pathname
    if (initalPath.startsWith("/contents/")) {
      dispatch(authActions.setInitialRoute({ url: initalPath }))
    } else {
      dispatch(authActions.setInitialRoute({ url: undefined }))
    }
  }, [dispatch])

  return <RouterProvider router={router}></RouterProvider>
}

export default AppRouter
