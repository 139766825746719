import React, { useEffect, ReactNode } from 'react';
import { router } from '../navigation';
import { client } from '../auth/service';

interface AxiosInterceptorProps {
  children: ReactNode;
}

const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ children }) => {


  useEffect(() => {
    const responseInterceptor = client.interceptors.response.use(
      response => response,
      error => {
        if (error.response && (error.response.status === 401 || error.code === "ERR_NETWORK")) {
          router.navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    return () => {
      client.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return <>{children}</>;
};

export default AxiosInterceptor;
