import React from "react";
import "./style.css";
import { Typography, styled } from "@material-ui/core";
type Props = {
  children: string;
};

const TypographyLight = styled(Typography)(({ theme }) => ({
  color:theme.palette.primary.light
  // paddingBottom: theme.spacing(1),
  // Override media queries injected by theme.mixins.toolbar
  // '@media all': {
  //   minHeight: 128,
  // },
}));

const LoadingDots = (props: Props) => {
  const { children } = props;
  return (
    <TypographyLight color="primary" className="loading-dots">
      {children}
    </TypographyLight>
  );
};

export default LoadingDots;
