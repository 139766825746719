import { createTheme } from "@material-ui/core";

const THEME = createTheme({
  palette: {
    primary: { 
        main: "#2E5DA6",
        light:'#185FAC' 
    },
    text:{
        secondary:'#00000099'
    },
    action:{
      hover:"#DBECF8",
      // focus:"#DBECF8",
      // selected:"#DBECF8",
      // active:"#DBECF8",
      
    }
  },
});

export default THEME;
