import { useCallback } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import MenuPopper from "./MenuPopper";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigation,
  useRoutes,
} from "react-router-dom";
import { usePathMatch } from "../../hooks/usePathMatch";
import { Variant } from "@material-ui/core/styles/createTypography";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "center",
  paddingTop: theme.spacing(2),
  // paddingBottom: theme.spacing(1),
  // Override media queries injected by theme.mixins.toolbar
  // '@media all': {
  //   minHeight: 128,
  // },
}));

type Props = {
  title?: string;
  hideButtons?: boolean;
  textVariant?: Variant;
};

const NavBar = (props: Props) => {
  const { title = "Content Hub", hideButtons, textVariant = "body2" } = props;

  const isMatch = usePathMatch("/contents");


  const navigate = useNavigate();
  const clickback = useCallback(() => {
    navigate(-1);
  }, [navigate]);
  
  return (
    <AppBar color="transparent" elevation={0} position="static">
      <StyledToolbar color="#fff">
        {!isMatch && !hideButtons && (
          <Box position={"absolute"}>
            <IconButton
              size="medium"
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={clickback}
              // sx={{ mr: 2 }}
            >
              <ChevronLeft />
            </IconButton>
          </Box>
        )}
        <div style={{ cursor: "default", flexGrow: 1 }}>
          <a style={{textDecoration:'none', cursor:'default'}} href={'/contents/'}>
            <Typography

              variant={ "h6" }
              noWrap
              component="div"
              align="center"
              style={{ fontWeight: "bold" }}
              color="primary"
            >
              {title}
            </Typography>
          </a>
        </div>
        {!hideButtons && <MenuPopper />}
      </StyledToolbar>
    </AppBar>
  );
};

export default NavBar;
