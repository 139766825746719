import { useEffect, useState } from "react"
import { matchPath } from "react-router-dom"

export const usePathMatch = (path: string) => {

  const [isMatch, setIsMatch] = useState(false)
  useEffect(() => {
    const callback = () => {
      if (matchPath(path, window.location.pathname)) {
        setIsMatch(true)
      } else {
        setIsMatch(false)
      }
    }
    callback()
    window.addEventListener("pushstate", callback)
    window.addEventListener("popstate", callback)

    return () => {
      window.removeEventListener("pushstate", callback)
      window.removeEventListener("popstate", callback)
    }
  }, [path])
  return isMatch
}
