import "./App.css";
import AppRouter from "./navigation";
import { Provider } from "react-redux";
import store, { persistor } from "./store/store";
import { ThemeProvider } from "@material-ui/core";
import THEME from "./theme/theme";
import { PersistGate } from "redux-persist/integration/react";
import AxiosInterceptor from "./utils/AxiosInterceptor";
import NavigationHistoryProvider from "./context/navigationHistoryContext";

(function (history) {
  var originalPushState = history.pushState;

  history.pushState = function (state) {
    const paths = state.path?.trim()?.split("/");
    // // const path = state?.path?.spilt('/')
    // if(paths && paths?.length<=3){
    var pushStateEvent = new Event("pushstate");
    originalPushState.apply(history, arguments as any);
    window.dispatchEvent(pushStateEvent);
    // }
  };
})(window.history);

function App() {
  // return (<DefaultLoading/>)

  return (
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AxiosInterceptor>
            <NavigationHistoryProvider>
              <AppRouter />
            </NavigationHistoryProvider>
          </AxiosInterceptor>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
