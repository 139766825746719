import {
  Box,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { KeyboardArrowDown, MoreVert } from "@material-ui/icons";
import { logout } from "../../auth/service";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../auth/slice";

const MenuPopper = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(
    (state) => state.authReducer.selectedAccount?.name
  );

  const email = useAppSelector((state) => state.authReducer.userProfile?.email);

  const match = useMediaQuery("(min-width:600px)");

  const accounts = useAppSelector(
    (state) => state.authReducer.userProfile?.accounts ?? []
  );

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const prevOpen = React.useRef(open);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback((event: any) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }, []);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const navigate = useNavigate();

  const handleLogout = (event: any) => {
    logout().then(() => {
      handleClose(event);
      dispatch(authActions.reset());
      navigate("/login/");
    });
  };

  const onAccountClick = (event: any) => {
    if (accounts.length > 1) {
      handleClose(event);
      navigate("/accounts/", { state: { isMenu: true } });
    }
  };

  return (
    <Box position="absolute" right={0} pr={2}>
      <IconButton
        aria-label="display more actions"
        edge="end"
        color="primary"
        onClick={handleToggle}
        innerRef={anchorRef}
      >
        {match ? (
          <Box display={"flex"} justifyContent={"center"} dir="row">
            <Typography color="textPrimary" variant="caption">
              {email}
            </Typography>
            <KeyboardArrowDown color="action" fontSize="small" />{" "}
          </Box>
        ) : (
          <MoreVert />
        )}
      </IconButton>
      <Popover
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
            >
              <MenuItem onClick={onAccountClick}>
                <div>
                  <Typography variant="body2">Account</Typography>
                  <Typography style={{ fontSize: 10 }} color="primary">
                    {name}
                  </Typography>
                  {!match && <Typography
                    style={{
                      fontSize: 10,
                      maxWidth: 200,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color="textSecondary"
                    // color="primary"
                  >
                    {email}
                  </Typography>}
                </div>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Typography style={{ minWidth: 100 }} variant="body2">
                  Logout
                </Typography>
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </Box>
  );
};

export default MenuPopper;
