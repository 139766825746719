import { useEffect, useState } from "react";

function useIsPortrait() {
    // A function to determine if the screen is in portrait orientation
    const getOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      return height >= width;
    };
  
    const [isPortrait, setIsPortrait] = useState(getOrientation());
  
    useEffect(() => {
      const handleResize = () => {
        setIsPortrait(getOrientation());
      };
  
      window.addEventListener('resize', handleResize);
      // Call handleResize immediately to set the initial orientation
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isPortrait;
  }

export default useIsPortrait