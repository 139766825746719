import useIsTablet from "../../hooks/useIsTable";
import useIsPortrait from "../../hooks/useIsPortrait";
import { ReactComponent as MascotLanding } from "./assets/AuditApp_Welcome_mascot.svg";
import { Box } from "@material-ui/core";

const MascotWelcome = () => {
  const isTablet = useIsTablet();
  const isPortrait = useIsPortrait();
  return (
    <Box style={{transform: 'translateX(10px)'}} minWidth={305} minHeight={225}>
    <MascotLanding/>
    </Box>
  );
};

export default MascotWelcome;
