import { Box, styled } from "@material-ui/core";

const FullScreenBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
}));

export default FullScreenBox;
