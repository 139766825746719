import { useCallback } from "react"
import { login } from "../auth/service"
import FullScreenBox from "../components/views/FullScreenBox"
import { Box, Button, Typography } from "@material-ui/core"
import MascotWelcome from "../components/mascot/MascotWelcome"

const LoginContainer = () => {
  const handleLogin = useCallback(() => {
    login()
  }, [])

  return (
    <FullScreenBox
      // bgcolor={"red"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Box mb={3}>
        <MascotWelcome />
      </Box>
      {/* <Box> */}
      <Typography align='center' variant='h5' color='primary'>
        Welcome To
      </Typography>
      <Typography
        align='center'
        variant='h5'
        style={{ fontWeight: "700" }}
        color='primary'
      >
        Content Hub!
      </Typography>
      <Box mt={2}>
        <Typography align='center' color='primary'>
          Create a consistent experience
        </Typography>
        <Typography align='center' color='primary'>
          for your customers in every store.
        </Typography>
      </Box>
      <Box width={"90%"} maxWidth={380} my={5}>
        <Button
          onClick={handleLogin}
          color='primary'
          variant='contained'
          size='large'
          fullWidth
        >
          Log in
        </Button>
      </Box>
    </FullScreenBox>
  )
}

export default LoginContainer
