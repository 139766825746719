import { useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { Box, MenuItem, Typography, styled } from "@material-ui/core"
import { ChevronRight } from "@material-ui/icons"
import NavBar from "../components/appBar/NavBar"
import { useLocation, useNavigate } from "react-router-dom"
import { authActions } from "../auth/slice"

const Container = styled(Box)(({ theme }) => ({
  alignItems: "center",
}))

const AccountContainer = styled(MenuItem)(({ theme }) => ({
  paddingBlock: theme.spacing(2),
  paddingInline: theme.spacing(3),
  flexDirection: "row",
  display: "flex",
}))

const AccountsContainer = () => {
  const accountList = useAppSelector(
    (state) => state.authReducer.userProfile?.accounts
  )

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const email = useAppSelector((state) => state.authReducer.userProfile?.email);

  const isMenu = location.state.isMenu
  const onPress = useCallback(
    (account) => {
      dispatch(authActions.setSelectedAccount({ account }))
      navigate("/", { replace: true, state: { isRedirect: true } })
    },
    [dispatch, navigate]
  )

  return (
    <>
      <NavBar textVariant='h6' hideButtons={!isMenu} title='Account Switch' />
      <Container>
        <Box p={3} pb={2}>
          <Typography color="primary" gutterBottom variant="body2">
            {email}
          </Typography>
          <Typography gutterBottom variant='body1'>
            Please select an account to switch.
          </Typography>
        </Box>
        {accountList?.map((account) => {
          return (
            <AccountContainer
              key={`${account.id}`}
              onClick={() => onPress(account)}
            >
              <Typography
                color='textSecondary'
                variant='body2'
                style={{ flexGrow: 1, fontWeight: "bold" }}
              >
                {account.name}
              </Typography>
              <ChevronRight color='primary' />
            </AccountContainer>
          )
        })}
      </Container>
    </>
  )
}

export default AccountsContainer
