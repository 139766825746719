import LoadingIcon from "./loadingIcon.svg"
import LoadingDots from "../animations/LoadingDots"

const DefaultLoading = () => {
  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <div> */}
      <img src={LoadingIcon} style={{ marginBottom: 24 }} alt='loading icon' />
      <LoadingDots>We are getting things done</LoadingDots>
      {/* </div> */}
    </div>
  )
}

export default DefaultLoading
