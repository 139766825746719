import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Account, AuthState, UserProfile } from "./type"

const initialState: AuthState = {}

export const authSlice = createSlice({
  initialState: initialState,
  name: "auth",
  reducers: {
    setSelectedAccount: (
      state,
      action: PayloadAction<{ account: Account }>
    ) => {
      return {
        ...state,
        selectedAccount: action.payload.account,
      }
    },
    // setUserAccountList: (
    //   state,
    //   action: PayloadAction<{ accounts: Array<Account> }>
    // ) => {
    //   return {
    //     ...state,
    //     userAccountsList: action.payload.accounts,
    //   };
    // },
    setContentRepoToken: (
      state,
      action: PayloadAction<{ contentRepoAccessToken?: string }>
    ) => {
      return {
        ...state,
        contentRepoAccessToken: action.payload.contentRepoAccessToken,
      }
    },
    setUserProfile: (state, action: PayloadAction<{ user: UserProfile }>) => {
      return {
        ...state,
        userProfile: action.payload.user,
      }
    },
    setIsLoggedIn: (state, action: PayloadAction<{ isLoggedIn: boolean }>) => {
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
      }
    },
    setInitialRoute: (state, action: PayloadAction<{ url?: string }>) => {
      return {
        ...state,
        intialRoute: action.payload.url,
      }
    },
    reset: () => {
      return {}
    },
  },
})
export const { reducer: authReducer, actions: authActions } = authSlice
